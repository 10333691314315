import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';

export type ProfileState = {
    currentModule: string;
    ids: string[];
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        currentModule: '',
        ids: [],
    } as ProfileState,
    reducers: {
        setProfile: (state, action: PayloadAction<{ profile: string[] }>) => {
            const { profile } = action.payload;
            state.ids = profile;
            console.log('CURRENT PROFILE', state.ids)
        },
        setCurrentModule: (state, action: PayloadAction<{ moduleName: string }>) => {
            const { moduleName } = action.payload;
            state.currentModule = moduleName;
            console.log('CURRENT MODULE', state.currentModule);
        },
        resetProfile: state => {
            state.ids = [];
            state.currentModule = '';
            console.log('PROFILE RESET');
        },
    },
});

export const selectProfile = (state: RootState) => state.profile.ids;
export const selectCurrentModule = (state: RootState) => state.profile.currentModule;

export const { setProfile, setCurrentModule, resetProfile } = profileSlice.actions;

export default profileSlice.reducer;
